@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;300;400;700;900&display=swap");

* {
  /* user-select: none !important; */
  font-family: 'Lato';
}

body {
  padding: 0px !important;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

:root {
  --blue: #303f7b;
  --white: #ffffff;
  --red: #c90f22;
  --black: #000000;
  --gray: #6d6e71;
  --light-black: #231f20;
  --slider-tab-color: #bcbcbc;
}

.main-page {
  min-height: 100vh;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.carousel-slider {
  height: 100%;
}

.carousel-slider .carousel-text .main-gril-img {
  /* height: 100vh; */
  /* object-fit: cover; */
  /* height: 100%; */
  width: 100%;
  z-index: 1;
}

.animation-img {
  height: 100%;
  width: 100%;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 0px;
  background: transparent;
  z-index: 22;
  left: 0;
  right: 0;
}

.header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo {
  height: 127px;
  width: 180px;
  border-radius: 0 0 15px 15px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 20px 40px;
}

.header .logo img {
  width: 100%;
  height: 100%;
  border-radius: 0 0 15px 15px;
}

.header .header-links {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.header .header-links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  margin: 0;
}

.header .header-links ul li {
  list-style: none;
}

.header .header-links ul li a {
  list-style: none;
  color: var(--white);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.textcolorblue {
  color: var(--blue) !important;
}

.header .header-links button {
  height: 36px;
  width: 150px;
  border-radius: 40.5px;
  background-color: var(--red);
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  outline: none !important;
  border: none;
}

.header .header-links .user {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
}

.responsive-header-profile-pic button {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
  border: none;
}

.responsive-header-profile-pic button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header .header-links .language {
  height: 25.01px;
  width: 67px;
  color: var(--white);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header .header-links .user img,
.header .header-links .language img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-slider .carousel-caption {
  right: 66% !important;
  bottom: 21.25rem !important;
  left: 15%;
  width: 600px;
}

.carousel-slider .carousel-text h3 {
  /* color: var(--white); */
  font-family: Lato;
  font-size: 54px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 54px;
}

.carousel-slider .carousel-text h3 span {
  color: var(--red);
  position: relative;
}

/* .carousel-slider .carousel-text h3 span::before{
  content: url(./assets/img/Path.png);
  position: absolute;
  left: 0px;
  bottom: -28px;
} */

.carousel-text {
  padding-top: 100px;
  padding-left: 45px;
}

.carousel-slider .carousel-indicators {
  bottom: 80px !important;
  right: unset !important;
  left: 50px !important;
  height: 5px !important;
}

.carousel-slider .carousel-indicators [data-bs-target] {
  height: 6px !important;
  background: var(--white) !important;
  border: none !important;
  border-radius: 5px;
  opacity: 1 !important;
}

.carousel-slider .carousel-indicators button.active {
  /* border-radius: 13px !important; */
  background: var(--red) !important;
}

.carousel-slider .carousel-control-prev:hover {
  color: var(--red) !important;
}

.slector-search {
  position: absolute;
  bottom: 21%;
  left: 16%;
  z-index: 2;
}

.slector-search .slector-search-inner {
  box-sizing: border-box;
  height: 48px;
  width: 371px;
  border: 2px solid var(--white);
  border-radius: 24px;
  background-color: rgba(20, 38, 108, 0.66);
  display: grid;
  grid-template-columns: 100px auto;
  align-items: center;
}

.slector-search .slector-search-inner .search {
  height: 100%;
  position: relative;
}

.slector-search .slector-search-inner .search .search-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  font-weight: 900;
  font-size: 25px;
  height: 100%;
  width: 50px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slector-search .slector-search-inner .search input {
  background: transparent;
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.63);
  padding: 0px 50px 0px 10px;
  color: #e6e6e6;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
}

.slector-search .slector-search-inner .selector {
  padding: 0 5px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.slector-search .slector-search-inner .selector .downarrow {
  font-size: 25px;
  color: var(--white);
  line-height: 16px;
}

.slector-search .slector-search-inner .selector p {
  margin: 0;
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.slector-search .slector-search-inner .selector .selector-option {
  width: 111px;
  height: 111px;
  position: absolute;
  bottom: -135px;
  left: 0;
  border-radius: 9px;
  background-color: var(--white);
  z-index: 2;
  padding: 15px 0;
}

.slector-search .slector-search-inner .selector .selector-option::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: var(--white);
  top: -7.5px;
  left: calc(50% - 7.5px);
  transform: rotate(45deg);
  z-index: 1;
}

.slector-search .slector-search-inner .selector .selector-option ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.slector-search .slector-search-inner .selector .selector-option ul li {
  list-style: none;
  text-align: left;
  cursor: pointer;
  opacity: 0.5;
  color: var(--black);
  font-family: Lato;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 30px;
}

.slector-search .slector-search-inner .selector .selector-option ul li:hover {
  opacity: 1;
  transition: 0.2s;
}

.nav-sticky {
  background: #ffffff;
  border-radius: 0 0 20px 20px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 30%);
  position: relative;
  animation: navsticky;
  animation-duration: 0.8s;
}

@keyframes navsticky {
  from {
    top: -110px;
  }

  to {
    top: 0px;
  }
}


.nav-back {
  background: #ffffff;
  border-radius: 0 0 20px 20px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 30%);
  position: relative;
  animation: backsticky;
  /* top: 0px; */
  /* transition: all 1ms; */
  animation-duration: 0.8s;
}

@keyframes backsticky {
  from {
    top: 0px;
  }

  to {
    top: -110px;
  }
}

.grow-together {
  width: 100%;
  min-height: 300px;
  /* padding: 20px; */
  overflow: hidden;
  position: relative;
}

.grow-together::after {
  position: absolute;
  content: "";
  left: -42px;
  top: 25px;
  background: url(./assets/img/dottedbg.svg);
  width: 132px;
  height: 116px;
  z-index: -1;
}

.grow-together .title,
.know-employee .title,
.get-started-carousel .title {
  position: relative;
  padding-left: 15px;
}

.grow-together .title::after,
.get-started-carousel .title::after,
.know-employee .title::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 22px;
  width: 6px;
  background: var(--red);
  border-radius: 10px;
}

.grow-together .title h1,
.know-employee .title h1 {
  color: var(--blue);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 29px;
}

.know-employeeheader {
  color: var(--blue) !important;
  /* font-family: Lato !important;
  font-size: 24px !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
  line-height: 29px !important; */
}

.grow-together-card {
  min-height: 208px;
  /* width: 100%; */
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.grow-together-card:hover {
  transform: scale(1.09);
  transition: ease-in-out 0.5s;
}

.grow-together-card h1 {
  color: var(--blue);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.grow-together-started {
  width: 100%;
  height: 92px;
  border-radius: 15px;
  background: url(./assets/img/GetStarted-home.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 0px 20px;
  /* flex-wrap: wrap; */
}

.grow-together-started h1 {
  color: var(--white);
  font-family: Lato;
  font-size: 36px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 34px;
  display: flex;
  flex-wrap: wrap;
}

.grow-together-started h1 .span2 {
  color: var(--blue);
  font-weight: bold;
}

.grow-together-started button {
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  outline: none;
  height: 48px;
  width: 200px;
  border-radius: 40.5px;
  background-color: var(--red);
  border: none;
}

.acquire-new-skills {
  width: 100%;
  /* min-height: 80vh; */
  min-height: 70vh;
  background: url(./assets/img/Group43.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  background-position: 20% 46%;
  position: relative;
}

.acquire-new-skills .title h1 {
  color: var(--white);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 29px;
  position: relative;
  padding-left: 15px;
}

.acquire-new-skills .title h1::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 22px;
  width: 6px;
  background: var(--red);
  border-radius: 10px;
}

.acquire-new-skills .title p {
  color: var(--white);
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0;
  padding-left: 15px;
}

.acquire-card {
  width: 100%;
  height: 415px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.acquire-card-time {
  height: 460px !important;
}

.course_video_card .acquire-card {
  height: 100%;
}

.acquire-card .mainImg {
  width: 100%;
  height: 264px;
  object-fit: cover;
}

.details {
  padding: 10px 20px;
}

.details .details-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details .details-title h1 {
  color: var(--blue);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.details .details-title .icon {
  color: var(--red);
  font-size: 22px;
  cursor: pointer;
}


.details .text {
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* word-wrap: break-word; */
  height: 48px;
  /* width: 310px; */
  /* white-space: nowrap; */
}

.job-card-detail .text {
  height: auto;
}

/* .details .text {
  color: var(--gray);
  font-family: Lato;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
} */

.details .enroll-btn {
  outline: none;
  border: none;
  background: none;
  color: var(--red);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.details .enroll-btn span {
  margin-left: 7px;
  font-size: 20px;
}

.addon-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.addon-btn img {
  width: 100%;
  height: 100%;
}

.acquire-card .share {
  position: absolute;
  height: 219.41px;
  width: 168.38px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  right: 56px;
  bottom: 86px;
}

.acquire-card .share .social-share {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background-color: var(--white);
  /* box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07); */
  overflow: hidden;
  padding: 10px 20px;
}

.acquire-card .share::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background: var(--white);
  bottom: 16px;
  right: -10px;
  transform: rotate(45deg);
  z-index: 1;
  border-right: 1px solid rgb(247, 243, 243);
  border-top: 1px solid rgb(247, 243, 243);
}

.acquire-card .share .social-share figure {
  width: 24px;
  height: 24px;
  margin: 0;
}

.acquire-card .share .social-share figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acquire-card .share .social-share ul {
  padding: 0;
}

.acquire-card .share .social-share ul li {
  list-style: none;
  color: var(--light-black);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.acquire-card .share .social-share ul li:last-child {
  border: none;
}

.acquire-card .share .social-share ul li:hover {
  color: var(--black);
  cursor: pointer;
}

.viewall-btn {
  text-align: center;
}

.viewall-btn button {
  height: 48px;
  width: 200px;
  border-radius: 40.5px;
  background-color: var(--red);
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31);
  outline: none;
  border: none;
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 30px;
}

.know-employee .slick-next::before,
.know-employee .slick-prev::before {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: calc(50% - 38px);
  color: var(--black);
  font-size: 51px !important;
}

.know-employee .slick-next::before {
  content: "\203A" !important;
}

.know-employee .slick-prev::before {
  content: "\2039" !important;
}

.know-employee .slick-prev:hover:before,
.know-employee .slick-next:hover:before {
  color: var(--red) !important;
}

.know-employee .slick-dots li button:before {
  font-size: 0px !important;
  height: 5px !important;
  border-radius: 10px;
  content: "" !important;
  background: var(--gray) !important;
}

.know-employee .slick-dots li button:hover:before {
  background: var(--red) !important;
}

.know-employee .slick-dots .slick-active button:before {
  background: var(--red) !important;
}

.text-slider {
  width: 100%;
  padding: 20px 20px 50px 20px;
}

.text-slider h3 {
  color: var(--blue);
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
}

.text-slider .post-name {
  color: var(--gray);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.text-slider .detail-text p {
  color: var(--blue);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
}

.know-employee .carousel-indicators {
  /* display: unset !important;
  justify-content: unset !important;
  right: unset !important;
  left: -64px !important;
  bottom: -3px; */
  bottom: 116px !important;
}

.know-employee .carousel-control-next,
.know-employee .carousel-control-prev {
  top: unset !important;
  display: unset !important;
  justify-content: unset !important;
  right: unset !important;
  bottom: 10px !important;
  width: 25px;
  height: 25px;
}

.know-employee .carousel-control-prev {
  /* left: 15px !important; */
  left: calc(50% - 85px);
  bottom: 116px !important;
}

.know-employee .carousel-control-next {
  /* left: 136px !important; */
  right: calc(50% - 85px) !important;
  bottom: 116px !important;
}

.know-employee .carousel-control-next-icon {
  background-image: url(./assets/img/rightarrow.svg) !important;
  background-repeat: no-repeat;
  width: 25px !important;
  height: 25px !important;
}

.know-employee .carousel-control-prev-icon {
  background-image: url(./assets/img/leftarrow.svg) !important;
  background-repeat: no-repeat;
  width: 25px !important;
  height: 25px !important;
}

.know-employee .carousel-indicators [data-bs-target] {
  height: 5px !important;
  width: 20px !important;
  background: var(--slider-tab-color) !important;
  border: none !important;
  border-radius: 5px;
  opacity: 1 !important;
}

.know-employee .carousel-indicators button.active {
  background: var(--red) !important;
}

.know-employee .carousel-control-next-icon:hover {
  background-image: url(./assets/img/rightarrow-red.svg) !important;
}

.know-employee .carousel-control-prev-icon:hover {
  background-image: url(./assets/img/leftarrow-red.svg) !important;
}

.know-employee .carousel,
.know-employee .carousel-inner {
  position: unset !important;
}

/* .community-members {
  height: 500px;
  width: 500px;
  position: relative;
  background-image: url(./assets/img/Group44.png);
  background-repeat: no-repeat;
} */

/* .community-members::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: var(--red);
  height: 100%;
  width: 100%;
  border-radius: 0 50% 50% 50%;
  z-index: -1;
} */

/* .community-member img {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -57px;
} */

/* .community-member img {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 7px;
  top: 133px;
  left: 79px;
} */

.community-members img {
  /* position: absolute; */
  width: 270px;
  height: 270px;
  border-radius: 7px;
  margin: 40px 40px 0px 0px;
}


.community-members {
  width: 460px;
  height: 495px;
  display: grid;
  place-items: center;
  background-image: url(./assets/img/Group44.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.community-members .play-icon {
  position: absolute;
  width: 60px;
  height: 60px;
}

.testimonial-card {
  position: relative;
}

.testimonial-card .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.guid-download {
  background-image: url(./assets/img/Group43.svg);
  /* min-height: 55vh; */
  padding: 50px 0px;
  background-repeat: no-repeat;
  background-position: 20% 46%;
  background-size: cover;
}

.download {
  width: 100%;
  min-height: 292px;
  background-image: url(./assets/img/download-bg.png);
  background-position: 100% 100%;
  background-size: cover;
  border-radius: 20px;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.download figure {
  margin: 0;
}

.download figure img {
  height: 100%;
  width: 100%;
}

.download-text h1 {
  /* width: 423px; */
  color: var(--white);
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
}

.download-text p {
  color: var(--white);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
}

.download-btn {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 10px;
}

.download-btn img {
  height: auto;
  width: auto;
}

.get-started-carousel .title h1 {
  color: var(--white);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
}

.getstarted-left h1 {
  /* height: 60px;
  width: 424px; */
  color: var(--white);
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
}

.getstarted-left ol li {
  color: var(--white);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
}

.hashTag p {
  /* height: 198px;
  width: 488px; */
  color: var(--red);
  font-family: Lato;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 40px;
}

.getstarted-right {
  width: 100%;
  height: 100%;
}

.getstarted-right img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.get-started-carousel .carousel-control-next-icon {
  background-image: url(./assets/img/rightarrow.svg) !important;
  background-repeat: no-repeat;
  width: 25px !important;
  height: 25px !important;
}

.get-started-carousel .carousel-control-prev-icon {
  background-image: url(./assets/img/leftarrow.svg) !important;
  background-repeat: no-repeat;
  width: 25px !important;
  height: 25px !important;
}

.get-started-carousel .carousel-indicators [data-bs-target] {
  height: 5px !important;
  width: 20px !important;
  background: var(--slider-tab-color) !important;
  border: none !important;
  border-radius: 5px;
  opacity: 1 !important;
}

.get-started-carousel .carousel-indicators button.active {
  background: var(--red) !important;
}

.get-started-carousel .carousel-control-next-icon:hover {
  background-image: url(./assets/img/rightarrow-red.svg) !important;
}

.get-started-carousel .carousel-control-prev-icon:hover {
  background-image: url(./assets/img/leftarrow-red.svg) !important;
}

.get-started-carousel .carousel-control-next,
.get-started-carousel .carousel-control-prev {
  position: absolute;
  top: unset !important;
  bottom: 30px !important;
  z-index: 1;
  display: unset !important;
  /* align-items: center; */
  /* justify-content: center; */
  width: max-content !important;
  height: max-content;
}

.get-started-carousel .carousel-control-next {
  left: 120px !important;
}

.get-started-carousel .carousel-indicators {
  right: unset !important;
  bottom: 39px;
  left: 30px;
  display: unset !important;
  justify-content: unset !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  /* list-style: none; */
}

.footer {
  width: 100%;
  min-height: 34vh;
  background: var(--red);
  padding: 30px 0 0 0;
}

.footer a {
  text-decoration: none;
}

.footer .contact-text {
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.footer-logo img {
  width: 115px;
  /* height: 175px; */
}

.contact-text img {
  width: 36px;
  height: 36px;
}

.footer .links p {
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  border-bottom: 1px solid #aeb1b8ec;
  padding: 10px 0;
}

.footer .links ul {
  padding: 0;
}

.footer .links li {
  list-style: none;
  margin-top: 10px;
}

.footer .links li a {
  color: var(--slider-tab-color);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
}

.footer-download p {
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0;
}

.footer-download .appstore {
  display: flex;
  column-gap: 10px;
  margin-top: 10px;
}

.footer-download .appstore figure {
  margin: 0;
  width: 122px;
  height: 36px;
}

.footer-download .appstore figure img {
  width: 100%;
  height: 100%;
}

.contact-with-social {
  color: var(--white);
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.09px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.contact-with-social a {
  color: var(--white);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.09px;
  cursor: pointer;
}

.copyright {
  width: 100%;
  background: #a41423;
  padding: 20px 0;
  margin-top: 21px;
}

.copyright-text p,
.privacy-policy a {
  color: var(--white);
  font-family: Lato;
  font-size: 13px;
  letter-spacing: 0.08px;
  line-height: 22px;
  margin: 0;
}

.copyright-text p,
.privacy-policy a:hover {
  color: var(--white);
}

.copyright-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.privacy-policy {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.privacy-policy a:first-child {
  /* border-right: 1px solid #000000; */
  position: relative;
  padding-right: 10px;
}

.privacy-policy a:first-child::after {
  /* border-right: 1px solid #000000; */
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  width: 2px;
  height: 64%;
  transform: translateY(-50%);
  background: var(--white);
}

.toggler {
  position: absolute;
  right: 54px;
  top: 34px;
  font-size: 32px;
  display: none;
  color: #000000;
}


.left-dots {
  position: absolute;
  top: 25%;
  left: 9%;
}

/* .Mui-focused {
  color: #c90f22 !important;
}

.Mui-checked{
  color: #c90f22 !important;
} */

.selecter .css-u9osun {
  color: #c90f22 !important;
}

.selecter .css-p0rm37 {
  color: #c90f22 !important;
}

.MuiInputLabel-root.Mui-focused {
  color: #c90f22 !important;
}

.selecter .css-fvipm8 {
  color: #c90f22 !important;
}

.selecter .css-u9osun.Mui-focused {
  color: #c90f22 !important;
}

.css-igs3ac {
  border-color: #c90f22 !important;
}

/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
  color: #c90f22 !important;
} */

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  border: 2px solid #c90f22;
} */


/* =========================>About<========================= */

.about-bg {
  background-image: url(./assets/img/about-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 57vh;
  background-position: center;
}

.top-content {
  margin-top: -100px;
}

.top-content .title {
  color: #FFFFFF;
  font-size: 24px;
  margin-left: 10px;
  position: relative;
}

.top-content .title::before {
  content: "";
  height: 100%;
  width: 5px;
  background-color: #c90f22;
  position: absolute;
  left: -15px;
  border-radius: 15px;
}

.title-2 {
  color: #202C63;
  font-size: 24px;
  font-weight: 900;
  margin-left: 10px;
  position: relative;
}

.about .title {
  font-weight: 900;
}

.title-2::before {
  content: "";
  height: 100%;
  width: 5px;
  background-color: #c90f22;
  position: absolute;
  left: -15px;
  border-radius: 15px;
}

.top-content .about-card {
  margin: 20px 0px;
  padding: 10px 20px;
  /* display: flex !important; */
  align-items: center;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
}

.top-content .about-card h3 {
  font-size: 20px;
  color: #231F20;
}

.top-content .about-card p {
  color: #6D6E71;
  font-size: 14px;
}

.about-slider-bg {
  background-repeat: no-repeat;
  background-color: #092B7E;
  height: 400px;
  margin-bottom: 60px;
  border-radius: 10px;
  position: relative;
}

.about-slider-bg::after {
  position: absolute;
  top: 20px;
  left: 20px;
  content: url(../src/assets/img/Shape.png);
}

.about-slider-text {
  padding: 0px 80px !important;
}

.about-slider-text h3 {
  max-width: 580px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
  height: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.about-slider-text h4 {
  max-width: 590px;
  margin-top: 30px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 27px;
}

.about-slider-text h5 {
  height: 22px;
  width: 590px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 22px;
}

.about-slider-bg h3 {
  /* line-height: 48px; */
  color: #FFFFFF;
  font-size: 32px;
}

.about-slider-bg h4 {
  color: #FFFFFF;
  font-size: 22px;
  margin-top: 20px;
}

.about-slider-bg h5 {
  color: #FFFFFF;
  font-size: 18px;
}

.about .know-employee .slick-dots {
  position: absolute;
  bottom: -12%;
  left: 0%;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.about .know-employee .slick-next {
  right: 43% !important;
  z-index: 1;
  top: 108% !important;
}

.about .know-employee .slick-prev {
  left: 43% !important;
  z-index: 1;
  top: 108%;
}

.banner {
  background-color: #092B7E;
  margin-top: 80px;
  border-radius: 15px;
  margin-bottom: 50px;
  padding: 20px;
}

.banner-content h2 {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  font-family: lato;
}

.banner-content button {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  font-family: lato;
  background-color: #C90F22;
  box-shadow: rgba(201, 15, 34, 0.31);
  border: none;
  border-radius: 40.5px;
  height: 48px;
  width: 200px;
  margin-top: 27px;
}

/* ==>termsandconditions<== */

.termsandconditions_bg {
  background-image: url(./assets/img/Group43.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 180px;
  background-position: center;
}

.termsandconditions_content {
  margin-top: -10%;
}

.termsandconditions_content .card {
  background-color: #FFFFFF;
  padding: 15px 40px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}


.termsandconditions_content .title {
  color: #FFFFFF;
  font-size: 24px;
  margin-left: 10px;
  position: relative;
}

.termsandconditions_content .title::before {
  content: "";
  height: 100%;
  width: 5px;
  background-color: #c90f22;
  position: absolute;
  left: -15px;
  border-radius: 15px;
}

.termsandconditions_content h5 {
  color: #2A2B2E;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.termsandconditions_content .detail {
  border: 1px solid #D1D3D4;
  padding: 25px;
  border-radius: 10px;
}

.termsandconditions_content .detail h5 {
  font-size: 14px;
  font-weight: bold;
  color: #231F20;
  margin-bottom: 10px;
}

.termsandconditions_content .detail p {
  font-size: 14px;
  color: #6D6E71;
}

.termsandconditions_content .detail p {
  font-size: 14px;
  font-weight: 600;
  color: #6D6E71;
}

.termsandconditions_content .detail ul li {
  font-size: 14px;
  list-style: none;
  font-weight: normal;
  color: #6D6E71;
  position: relative;
}

.termsandconditions_content .detail ul li::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #c90f22;
  ;
  position: absolute;
  border-radius: 50%;
  top: 7px;
  left: -20px;
}


/* ==>Contact<== */

.contact-card {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  height: 90%;
  /* width: 24%; */
}

/* .contact .growtogathercard {
  padding-top: 180px;
} */

.post-jobs-btn {
  background-color: #D01124 !important;
  border: none !important;
  height: 48px !important;
  width: fit-content;
  display: flex !important;
  position: fixed;
  align-items: center;
  left: 10%;
  top: 90%;
  box-shadow: 0 15px 20px 10px rgba(0, 0, 0, 0.1) !important;
  padding: 0px 25px !important;
  border-radius: 10px !important;
  align-items: center;
  z-index: 99;
  gap: 15px;
}

.post-jobs-btn:hover {
  background-color: #D01124;
  border: none !important;
  height: 48px !important;
}

.post-jobs-btn:focus {
  background-color: #D01124;
}

.contact-card .title {
  color: #231F20;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
  margin-top: 20px;
}

.contact-card .text {
  color: #8C8C8C;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 19px;
}

.red-button {
  border-radius: 40.5px;
  background-color: var(--red);
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  outline: none !important;
  border: none;
  padding: 10px 45px;
  box-shadow: 0px 6px 13px 4px rgba(201, 15, 34, 0.3);
}


/* ==><== */

.Dr_Ambedkar {
  background-image: url(./assets/img/Dr_Ambedkar.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 67vh;
  background-position: center;
  position: relative;
  display: flex !important;
  justify-content: space-between;
}

.carousel-slider .Dr_Ambedkar .carousel-text {
  flex-direction: column;
  text-align: left !important;
}

.carousel-slider .Dr_Ambedkar .carousel-text h3 {
  color: var(--white) !important;
  font-family: Lato;
  font-size: 54px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 54px;
}

.carousel-slider .carousel-text h3 {
  color: var(--white) !important;
  font-family: Lato;
  font-size: 54px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 54px;
}

.carousel-slider .carousel-text .carousel-text-name {
  color: var(--white) !important;
  color: #000 !important;
  font-family: Lato;
  font-size: 54px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 54px;
}

.carousel-slider .Dr_Ambedkar .carousel-text h4 {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
}

.carousel-slider .Dr_Ambedkar .carousel-text .location {
  display: flex;
  padding-right: 20px;
  /* border-right: 2px solid #FFFFFF; */
}

.carousel-slider .Dr_Ambedkar .carousel-text .location h5 {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: bold;
  margin-left: 12px;
}

.Beneficiary {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0;
}

.Schemes_Tab .nav-tabs .nav-link {
  width: 200px;
  word-wrap: normal;
}

/* width */
.Schemes_Tab .nav-tabs::-webkit-scrollbar {
  height: 5px;
  width: 20px;
}

.Schemes_Tab .nav-tabs::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.Schemes_Tab .nav-tabs {
  justify-content: space-between;
  align-items: center;
  /* width: 901px; */
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.search_input {
  position: relative;
}

.search_input input {
  border-radius: 50% !important;
  padding-right: 35px !important;
}

.search_input img {
  position: absolute;
  right: 9px;
  top: 12px;
}

.Schemes_video {
  position: relative;
  /* background-image: url(../src/assets/img/Schemes_video.png); */
  padding: 12% 0 0 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Healthcare-bg {
  background-image: url(../src/assets/img/Schemes_video.png);
  padding: 12% 0 0 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Prevention-bg {
  background-image: url(../src/assets/img/Dr_Ambedkar.png);
  padding: 12% 0 0 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.GDA-bg {
  background-image: url(../src/assets/img/Schemes_video.png);
  padding: 12% 0 0 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Schemes_video h1 {
  color: #FFFFFF;
  font-size: 36px;
  font-weight: bold;
  font-family: lato;
}

.Schemes_video h2 {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 72px;
  font-weight: 900;
  letter-spacing: 0;
}

.Schemes_video .play_button {
  display: flex;
  align-items: center;
  gap: 23px;
  margin-top: 20px;
}

.Schemes_video .play_button .text p {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.save_btn {
  box-sizing: border-box;
  height: 49px;
  background-color: transparent;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  width: 201px;
  border: 1px solid #FFFFFF;
  border-radius: 40.5px;
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31);
}

.view_btn {
  height: 48px;
  width: 200px;
  border-radius: 40.5px;
  background-color: #C90F22;
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31);
  border: none;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
}


/* ==>View Schemes<== */

.about_Schemes_card {
  position: absolute;
  /* bottom: -50%; */
  /* top: 100%; */
  /* margin-top: 450px; */
  transform: translatey(-50%);
  width: 100%;
}

.about_Schemes {
  box-sizing: border-box;
  border: 1px solid #E8E9E9;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
  padding: 20px 20px 20px 40px;
}

.scheme_name {
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scheme_name h1 {
  color: #2A2B2E;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.scheme_name .icon {
  color: var(--red);
  font-size: 22px;
  cursor: pointer;
}


.scheme_name .share {
  position: absolute;
  height: 219.41px;
  width: 168.38px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  right: 45px;
  left: -180px;
  bottom: 0px;
}

.scheme_name .share .social-share {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 555;
  border-radius: 10px;
  background-color: var(--white);
  /* box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07); */
  overflow: hidden;
  padding: 10px 20px;
}

.scheme_name .share::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background: var(--white);
  bottom: 16px;
  right: -10px;
  transform: rotate(45deg);
  z-index: 1;
  border-right: 1px solid rgb(247, 243, 243);
  border-top: 1px solid rgb(247, 243, 243);
}

.scheme_name .share .social-share figure {
  width: 24px;
  height: 24px;
  margin: 0;
}

.scheme_name .share .social-share figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scheme_name .share .social-share ul {
  padding: 0;
}

.scheme_name .share .social-share ul li {
  list-style: none;
  color: var(--light-black);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.scheme_name .share .social-share ul li:last-child {
  border: none;
}

.scheme_name .share .social-share ul li:hover {
  color: var(--black);
  cursor: pointer;
}

.scheme_name_text p {
  margin-top: 10px;
  color: #737477;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.scheme_name_text p span {
  font-weight: bold;
}

.about_Schemes_buttons {
  height: 100%;
  /* margin: 20px 0px; */
  text-align: center;
  border-left: 2px solid #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.border-red-btn {
  box-sizing: border-box;
  height: 49px;
  width: 201px;
  border: 1px solid #C90F22;
  border-radius: 40.5px;
  color: #C90F22;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31);
}

.border-red-btn-true {
  background-color: #C90F22;
  color: #FFFFFF !important;
  box-sizing: border-box;
  height: 49px;
  width: 201px;
  border-radius: 40.5px;
  border: 1px solid #C90F22;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31);
}

.download-file {
  text-decoration: none;
  color: #FFFFFF;
}

.download-file:hover {
  text-decoration: none;
  color: #FFFFFF;
}

.view_Schemes_video {
  /* background-image: url(../src/assets/img/view_Schemes_video.png); */
  padding: 12% 0 8% 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
}

.view_Schemes_video h1 {
  color: #FFFFFF;
  font-size: 36px;
  font-weight: bold;
  font-family: lato;
}

.view_Schemes_video h2 {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 72px;
  font-weight: 900;
  letter-spacing: 0;
}

.view_Schemes_video .play_button {
  display: flex;
  align-items: center;
  gap: 23px;
  margin-top: 20px;
}

.view_Schemes_video .play_button .text p {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0px;
}

.view_Schemes_accordion {
  padding: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  margin-bottom: 50px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
}

.howtoapply-accordion .accordion-item {
  border: none !important;
  margin-bottom: 10px;
}

.howtoapply-accordion .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  color: #D01124;
  background-color: #fafafa !important;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  border: 0;
  box-shadow: none !important;
  border-radius: 10px !important;
  overflow-anchor: none;
  /* transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease; */
}

.howtoapply-accordion .accordion-body {
  background-color: #fafafa;
  border-radius: 0px 0px 10px 10px;
  color: #6D6E71;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.howtoapply-accordion .accordion-button:not(.collapsed) {
  color: #D01124;
  border-radius: 10px 10px 0px 0px !important;
}

.howtoapply-accordion .accordion-button::after {
  background-image: url(./assets/img/accordion-arrow.png) !important;
}

.howtoapply-accordion .accordion-button:not(.collapsed)::after {
  background-image: url(./assets/img/accordion-arrow.png) !important;
}

.add_review {
  padding-top: 40px;
  border-top: 1.5px solid #EBEBEB;
}

.review_form {
  width: 500px;
}

.review_form p {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
}

.review_form p span {
  font-weight: bold;
}

.add_review .rating h2 {
  color: #2A2B2E;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
}

.rating {
  padding-bottom: 40px;
  border-bottom: 1.5px solid #EBEBEB;
}

.comments {
  display: flex;
  margin-bottom: 50px;
}

.profile_pic {
  width: 70px !important;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.comments .text .name {
  color: #2A2B2E;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 0;
}

.comments .text .comment_text {
  color: #6D6E71;
  font-family: Lato;
  font-size: 16px;
  width: 100%;
  word-break: break-all;
  letter-spacing: 0;
  /* margin-bottom: 40px; */
}

.like_reply {
  align-items: center;
  display: flex;
  gap: 50px;
}

.like {
  display: flex;
  align-items: center;
  gap: 10px;
}

.like img {
  height: 14.77px;
  width: 18px;
}

.like_count {
  margin-bottom: 0px;
  color: #545048;
  /* font-family: Gotham; */
  font-size: 16px;
  font-weight: 600;
}

.like h3 {
  color: #545048;
  /* font-family: Gotham; */
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.reply a {
  text-decoration: none;
  color: #D01124;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}

.reply p {
  text-decoration: none;
  color: #D01124;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.reply a:hover {
  color: #D01124;
}

.star-container {
  padding: 0px !important;
}

.bg-f7f8f9 {
  background-color: #f7f8f9;
  padding: 50px;
}

.testimonials-text {
  color: #605C54;
  /* font-family: Gotham; */
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
}

.Testimonials-slider-popup {
  height: 266.08px;
  width: 90%;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
  margin: 0px auto 20px auto;
  border-radius: 10px;
  position: relative;
  padding: 50px 20px 20px;
  display: none;
  left: 0px;
}

.slick-active .Testimonials-slider-popup {
  display: block;
}

.Testimonials-slider-popup::after {
  content: "";
  height: 25px;
  width: 25px;
  background-color: #FFFFFF;
  border-radius: 5px;
  position: absolute;
  bottom: -11px;
  transform: rotate(45deg);
  right: 50%;
}

.Testimonials-image {
  margin-top: 287px;
}

.slick-active .Testimonials-image {
  margin-top: 0px;
}

.slick-active .profile_pic {
  width: 85px !important;
  height: 85px;
  margin: 0 auto;
}

.Testimonials-slider-popup .text {
  color: #6D6E71;
  /* font-family: Gotham; */
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
}

.Testimonials-slider-popup .testimonials-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.Testimonials-slider-popup .name {
  text-align: end;
  color: #383838;
  /* font-family: Gotham; */
  font-size: 16px;
  font-weight: bold;
}

.Testimonials-slider-popup .role {
  text-align: end;
  color: #383838;
  /* font-family: Gotham; */
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
}

.view-schemes-faq {
  box-sizing: border-box;
  padding: 20px;
  /* margin: 50px auto; */
  border: 1px solid #E8E9E9;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
}

.faq-accordion {
  border: 1px solid #D1D3D4;
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 20px;
  display: flex;
  gap: 20px;
  position: relative;
}

.number {
  height: 75px;
  width: 75px;
  background-color: #D01124;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  /* font-family: Roboto; */
  font-size: 36px;
  font-weight: bold;
}

.faq-accordion .text .head {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.faq-accordion .text .head h1 {
  color: #545048;
  /* font-family: Gotham; */
  font-size: 16px;
  font-weight: 600;
}

.body-text {
  color: #6D6E71;
  /* font-family: Gotham; */
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
}

.view-faq {
  text-align: end;
}

.view-faq a {
  text-decoration: none;
  color: #D01124;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}

.view-faq a:hover {
  color: #D01124;
}


.choose-language-modal .modal-header {
  border: none !important;
}

.choose-language-modal .modal-header .btn-close {
  opacity: 1 !important;
  box-shadow: none !important;
}

.choose-language-modal .modal-body p {
  color: #231F20;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: start;
  margin-bottom: 30px;
}

.choose-language-modal .modal-body input {
  height: 21px !important;
  width: 21px !important;
  box-shadow: none !important;
}


.choose-language-modal .modal-body .form-check-input[type='checkbox'] {
  border-radius: 50% !important;
}

.choose-language-modal .modal-body .form-check-input:checked {
  background-color: #c90f22 !important;
  border-color: #c90f22 !important;
  margin-top: 4px;
}

.choose-language-modal .modal-body .choose-language-data {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.choose-language-modal .modal-body .choose-language-data button {
  height: 48px;
  width: 200px;
  border-radius: 40.5px;
  background-color: #C90F22;
  border: 1px solid #C90F22;
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31);
  margin: 40px 0px;
}

.choose-language-modal .modal-content {
  border-radius: 15px !important;
}

.view-courses {
  margin-top: 150px;
}

.documentation {
  margin: 20px;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #fafafa;
}

.documentation h3 {
  color: #D01124;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0px;
}

.documentation {
  white-space: pre-wrap;
  color: #D01124;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0px;
}


.view-scheme-recommendation .slick-prev {
  left: unset !important;
  right: 16%;
  top: -28px;
}

.view-scheme-recommendation .slick-next {
  right: 8%;
  top: -28px;
}


.view-scheme-recommendation .slick-prev:hover {
  background-color: #c90f22;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.view-scheme-recommendation .slick-prev:hover::before {
  color: #FFFFFF !important;
}

.view-scheme-recommendation .slick-next:hover {
  background-color: #c90f22;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.view-scheme-recommendation .slick-next:hover::before {
  color: #FFFFFF !important;
}

.know-employee.view-scheme-recommendation .slick-next::before {
  content: "\203A" !important;
  font-size: 45px !important;
}

.know-employee.view-scheme-recommendation .slick-prev::before {
  content: "\2039" !important;
  font-size: 45px !important;
}

.know-employee.view-scheme-recommendation .slick-next::before {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 2px;
  top: calc(50% - 36px);
  color: var(--black);
  font-size: 51px !important;
}

.know-employee.view-scheme-recommendation .slick-prev::before {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: -2px;
  top: calc(50% - 36px);
  color: var(--black);
  font-size: 51px !important;
}

.view-scheme-recommendation-card {
  padding: 10px 15px 50px 15px;
}

.view-scheme-recommendation .text-end .view-all-modules {
  color: #C90F22;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  text-decoration: none !important;
}

/* =======================================>Jobs<======================================= */

.jobs-hero-text .carousel-text h1 {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
}

.jobs-hero-text .carousel-text h3 {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}

.jobs-hero-text .carousel-text .location {
  display: flex;
  padding-right: 20px;
  border-right: 2px solid #FFFFFF;
}

.jobs-hero-text .carousel-text .location h5 {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: bold;
  margin-left: 12px;
}


.job-location {
  padding: 20px 12px;
  border-top: 1px solid #E9EBF1;
  border-bottom: 1px solid #E9EBF1;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.job-location .cityName p {
  height: 19px;
  width: 82px;
  color: #7f7f7f;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0px;
  margin-top: 10px;
  text-align: center;
}

.Top-Companies-job h1 {
  color: #202C63;
  font-family: Lato;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
}

.jobs-2nd-bg {
  background-color: #F7F8F9;
  padding: 20px 0 20px 0px;
}

.video-card .play_btn {
  position: absolute;
  top: 31%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Schemes_video .bottom-tabs {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
}

.Schemes_video .bottom-tabs button {
  width: 30%;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 2px solid #fcfcfc40 !important;
  box-shadow: none !important;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  padding: 20px 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Schemes_video .bottom-tabs .active-btn {
  border-bottom: 2px solid #FFFFFF !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.video {
  height: 800px;
}

/* ==>Learn<== */

.learn-hearo-text {
  position: absolute;
  top: 40%;
}


.set-slider-text h3 {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 66px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  width: 550px;
}

.set-slider-text .Beneficiary {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 650px;
}

.set-slider-text h4 {
  color: #FFFFFF;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 650px;
}

.set-slider-text h5 {
  color: #FFFFFF;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 650px;
}

.set-slider-text p {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 0px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 400px;
}

.set-slider-text .jobs-location p {
  width: fit-content !important;
}

.react-player {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 40%; */
  height: 450px;
  z-index: 999;
}

.starRating label {
  color: rgb(201, 15, 34) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: none !important;
  -webkit-box-shadow: none !important;
  transition: background-color 5000s ease-in-out 0s;
}

.file-input {
  height: 178px;
  width: 178px;
  border-radius: 5px;
  background-color: #E7EDF0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: center;
}

.file-input .selected-img {
  height: 100%;
  width: 100%;
}

.file-input img {
  height: 48px;
  width: 48px;
}

.file-input input {
  display: none;
}

.file-input-lable {
  border: 1px solid #A1ACB8;
  border-radius: 40.5px;
  padding: 10px 20px;
  color: #4B5057;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
}

.edit-profile .profile-detail h1 {
  color: #6D6E71;
  font-family: Lato;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
}

.edit-profile .profile-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.social-detail .delete-btn {
  border-radius: 15px;
  background-color: #F5F5F5 !important;
  color: #6D6E71 !important;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}

.edit-profile .change-photo-btn {
  color: #4B5057 !important;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border: 1px solid #A1ACB8 !important;
  background-color: transparent !important;
  border-radius: 40.5px !important;
}

.border-bottom {
  border-bottom: 1px solid #ebeff4 !important;
}

.profile-view .title {
  color: #1C2A6B;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.save-button {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border-radius: 40.5px !important;
  border: none !important;
  background-color: #C90F22 !important;
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31);
  padding: 15px 30px !important;
}

.upload-image-resume .modal-header {
  border: none !important;
  color: #6D6E71;
  font-family: Lato;
  font-size: 16.8px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 18px;
  padding: 30px;
}

.upload-image-resume .modal-body {
  padding: 0px 30px !important;
  padding-bottom: 20px !important;
}

.upload-image-resume .modal-body .file-input {
  width: 100% !important;
}

.upload-image-resume .modal-body .file-input p {
  color: #6D6E71;
  font-family: Lato;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
}

.upload-image-resume .modal-lg {
  max-width: 550px !important;
}

.upload-image-resume-lable {
  color: #85878B !important;
  /* font-family: Gotham !important; */
  font-size: 12px;
  font-weight: 300;
  width: 92% !important;
  text-align: left !important;
  letter-spacing: 0;
  margin-left: 10px;
  line-height: 16px;
}

.upload-image-resume .modal-header .btn-close {
  background-color: #E0E0E0 !important;
  border-radius: 50% !important;
  width: 25px;
  height: 25px;
}

.upload-image-resume .submit-btn {
  color: #FFFFFF !important;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  border: none !important;
  padding: 18px 80px;
  line-height: 16px;
  text-align: center !important;
  border-radius: 40.5px !important;
  background-color: #C90F22 !important;
  box-shadow: 0 6px 13px 4px rgba(201, 15, 34, 0.31) !important;
}

.Schemes_Tab ul li {
  text-align: center;
}

.Schemes_Tab ul .slick-prev:before {
  color: #000 !important;
  content: "\2039" !important;
  font-size: 40px;
}

.Schemes_Tab ul .slick-next:before {
  color: #000 !important;
  content: "\203A" !important;
  font-size: 40px;
}


.Schemes_Tab ul .slick-prev {
  left: -30px;
  top: -4px;
}

.Schemes_Tab ul .slick-next {
  right: -30px;
  top: -4px;
}

/* .view-scheme-tab-slider .slick-slide {
  width: auto !important;
  max-width: 206px !important;
} */
.text-end.view-all-modules {
  color: #c90f22;
}

.MuiRating-visuallyHidden {
  color: #c90f22 !important;
}

.show-password {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 13px;
  right: 15px;
}

.jobs-filter {
  position: relative;
  margin-top: -70px;
  z-index: 1;
  padding: 0px !important;
  border-radius: 15px;
  background: #FFFFFF;
}

.learn-tab .nav-item {
  padding-bottom: 3px;
}

.tab-main .nav-item {
  padding-bottom: 3px;
}


.tab-main .share {
  position: absolute;
  height: 219.41px;
  z-index: 1;
  width: 168.38px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  right: 60px;
  bottom: 0px;
}

.tab-main .share .social-share {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 555;
  border-radius: 10px;
  background-color: var(--white);
  /* box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07); */
  overflow: hidden;
  padding: 10px 20px;
}

.tab-main .share::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background: var(--white);
  bottom: 16px;
  right: -10px;
  transform: rotate(45deg);
  z-index: 1;
  border-right: 1px solid rgb(247, 243, 243);
  border-top: 1px solid rgb(247, 243, 243);
}

.tab-main .share .social-share figure {
  width: 24px;
  height: 24px;
  margin: 0;
}

.tab-main .share .social-share figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tab-main .share .social-share ul {
  padding: 0;
}

.tab-main .share .social-share ul li {
  list-style: none;
  color: var(--light-black);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}

.tab-main .share .social-share ul li:last-child {
  border: none;
}

.tab-main .share .social-share ul li:hover {
  color: var(--black);
  cursor: pointer;
}


/* ====================================>Partners<==================================== */


.bg-partners {
  background-image: url(./assets/img/partners.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 67vh;
  background-position: center;
  position: relative;
  /* display: flex !important; */
  /* justify-content: space-between; */
  /* margin-top: 135px; */
}

.partners-main {
  margin-top: -20%;
  position: relative;
}

.enroll-btn-text {
  color: var(--red);
  font-family: Lato;
  font-size: 14px !important;
  font-weight: bold !important;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.active-tab {
  font-weight: bold;
}

.job-hero-btn {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.details.job-card-detail .text {
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
  /* word-wrap: break-word; */
  /* height: 48px; */
  width: 310px;
  white-space: nowrap;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
 max-width: 100% !important;
} */

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  min-width: none !important;
}

.form-control {
  height: 48.96px;
}

.text-bold {
  color: #000 !important;
  font-weight: 800 !important;
  opacity: 1 !important;
}

.courses-count-box {
  box-sizing: border-box;
  margin: 0;
  padding: 25px;
  color: #272b41;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 10px;
}

.courses-count-box h4 {
  font-size: 26px;
  font-weight: 700;
}

.courses-count-box h6 {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.7;
}

.breadcrums {
  position: absolute;
  top: 140px;
  /* left: 16%; */
  z-index: 3;
  gap: 3px;
  display: flex;
  align-items: center;
}

.breadcrums_jobs {
  position: absolute;
  top: 140px;
  left: 16%;
  z-index: 3;
  gap: 3px;
  display: flex;
  align-items: center;
}

.breadcrums p {
  color: #FFFFFF !important;
  display: block;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;
}

.breadcrums button {
  border: none !important;
  background-color: transparent !important;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
}

.blue-text p {
  color: #303F7B !important;
}

.blue-text button {
  color: #303F7B !important;
}

.addpartnercourses .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100% !important;
}


/* View Partner Course <=============== */

.view-partner-course {
  text-align: center;
}

.view-partner-course img {
  border-radius: 12px !important;
}

.view-partner-course-details label {
  font-size: 16px;
  font-weight: bold;
  padding-right: 10px;
  color: #000;
}

.view-partner-course-details span {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.become-trainingpartner button {
  transform: rotate(-90deg);
  position: fixed;
  z-index: 999;
  left: -110px;
  top: 240px;
  border-radius: 0px 0px 10px 10px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 0 10px 0 #A9A9A9 !important;
  padding: 10px;
  border: none;
  color: #202C63;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  width: 260px;
  text-align: center;
}

.become-trainingpartner button:hover {
  border-radius: 0px 0px 10px 10px !important;
  background-color: #FFFFFF !important;
  color: #202C63 !important;
  box-shadow: 0 0 10px 0 #A9A9A9 !important;
}

.become-trainingpartner button:focus {
  border-radius: 0px 0px 10px 10px !important;
  background-color: #FFFFFF !important;
  color: #202C63 !important;
  box-shadow: 0 0 10px 0 #A9A9A9 !important;
}

/* Social floating<=============== */

.social-floating {
  position: fixed;
  right: -99px;
  z-index: 99;
  top: 200px;
}

.social-floating:hover {
  position: fixed;
  width: 148px;
  right: 0px;
  z-index: 99;
  top: 200px;
  transition: all ease-in 0.15s;
}

.social-floating button {
  border-radius: 10px 0px 0px 10px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 0 10px 0 #A9A9A9 !important;
  padding: 10px;
  display: block;
  margin-bottom: 5px;
  width: 100%;
  border: none !important;
  display: flex;
  gap: 20px;
  height: 48px;
  align-items: center;
}

.social-floating button img {
  width: 24px;
  /* object-fit: none; */
}

.social-floating button span {
  color: #D01124 !important;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.job-list {
  width: fit-content !important;
}

.job-list:hover {
  cursor: pointer;
  border-bottom: 1px solid;
}

.p-150 {
  padding-top: 150px !important;
}

.news-title h1 {
  margin: 25px 0;
  color: #202C63;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.news-bg {
  height: 470px;
}

.news-bg-title {
  top: 530px;
}

.ctaBTN {
  position: absolute;
  /* background-color: red;
  border-radius: "50%"; */
  right: 50px;
  top: -80px;
  z-index: 9999;
  background-color: #C90F22;
  color: #FFFFFF !important;
  box-sizing: border-box;
  height: 49px;
  width: 180px;
  border-radius: 40.5px;
  border: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  padding: 0px 15px;
  box-shadow: 0 6px 13px 4px rgb(201 15 34 / 31%);
  overflow: hidden;
  text-overflow: ellipsis;
}

.postJobSearchInput:focus {
  outline: none;
  caret-color: transparent;
}

.dashboard .share {
  position: absolute;
  height: 219.41px;
  z-index: 1;
  width: 168.38px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  right: 45px;
  bottom: -10px;
}

.top_job_table table tr:first-child .share::after {
  bottom: 169px;
}

.top_job_table table tr:first-child .share {
  bottom: -166px;
}

.okay-button-link {
  display: none !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 300px !important;
  overflow: auto !important;
  padding: 0px !important;
}

.company-logo {
  width: 290px;
  height: 170px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.disabled-link {
  pointer-events: none;
}

.select-menuitem {
  padding: 10px 10px !important;
  border-bottom: 1px solid #E0E0E0 !important;
}

.faq-vector {
  width: 100%;
}

.set-modules {
  margin-top: 155px;
}

.select-job-type {
  max-width: 255px;
}

.viweSingleJobDitailContainer {
  margin-top: -250px;
  position: relative;
  z-index: 2;
}

.jobCard_add {
  border-radius: 50px;
  background-color: #000480;
  color: #FFFFFF;
  font-weight: 500;
  height: 30px !important;
  padding: 0px 16px !important;
  line-height: 30px;
  font-size: 14px;
  border-radius: 24px;
  width: fit-content !important;
}

.jobCard_notAdd {
  /* background: #c3c3c3; */
  border-radius: 50px;
  background-color: #20C997;
  color: #FFFFFF;
  font-weight: 500;
  height: 30px !important;
  padding: 0px 16px !important;
  line-height: 30px;
  font-size: 14px;
  border-radius: 24px;
  width: fit-content !important;
}