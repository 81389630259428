.header-profile{
    width: 40px !important;
    height: 40px !important;
    overflow: hidden !important;
    border-radius: 50%;
    object-fit: cover;
}

.notification{
    position: relative;
}

.notification .notification-text{
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    background-color: #D01124;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 23px;
    position: absolute;
    top: -10px;
    right: -13px;
}

.notification_popup{
    position: absolute;
}

.cursor-pointer{
    cursor: pointer !important;
}
